@import "./scss/colorCodes.scss";
@import "~@angular/material/prebuilt-themes/purple-green.css";

.leaflet-control-layers {
  label {
    color: #000;
  }
}

html,
body {
  background-color: #0a0d14;
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

option[value=""][disabled] {
  display: none;
}

option:checked {
  color: rgba(255, 255, 255, 0.75);
}

a:hover {
  color: #3D7BF7 !important;
  text-decoration: none;
  font-weight: none;
}


::placeholder,
label {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
}

//date input icon color inverter
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.red-star {
  color: #fca6ad;
}

// .cdk-overlay-container {
//    display: none !important;
// }

//for unit inputs
.nrb {
  border-right-width: 0px !important;
  //   height: 1.5rem;
}

.nlb {
  border-left-width: 0px !important;
}

.nlrb {
  border-right-width: 0px !important;
  border-left-width: 0px !important;
}

.prefix {
  font-size: 1rem !important;
  background: var(--neutral-900, #1B1F27);
  border: 1px solid var(--neutral-700, #3F444D);
  outline: none;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.unit {
  text-align: right;
}

.make-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.make-flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.make-flex-row-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.make-flex-row-space-even {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.make-flex-col {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.make-flex-col-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.make-flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selected-option {
  color: rgba(255, 255, 255, 0.75);
}

.mat-table,
.cdk-table .mat-paginator,
.mat-paginator-container,
.mat-paginator-range-actions {
  background-color: #23272f;
  background: #23272f;

  td,
  th,
  .mat-icon-button,
  .mat-paginator-page-size,
  .mat-paginator-range-label {
    color: #fff;
  }
}

.mat-dialog-container {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent;
}

@mixin colors($color, $background-color) {
  color: $color;
  background-color: $background-color;
}

@mixin font($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}

// Text and Bg color
.white-text-opacity {
  color: $white-opacity;
}

.white-text {
  color: $white;
}

.cesium-scene-mode-picker-wrapper {
  display: block !important;
}

.black-light {
  background-color: $black-light;
}

.black-medium {
  background-color: $black-medium;
}

.black-dark {
  background-color: $black-dark;
}

.reject {
  background-color: $red;
}

.approve {
  background-color: $green;
}

.pending {
  background-color: $yellow;
}

.dark-red {
  background-color: $dark-red;
}

// Complaince
.complaince {
  width: 45px;
  height: 20px;
  border-radius: 5px;
}

// Font sizes
.f-20 {
  font-size: 20px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px;
}

.f-10 {
  font-size: 10px;
}

.f-24 {
  font-size: 24px;
}

// Borders
.br-8 {
  border-radius: 8px;
}

.br-15 {
  border-radius: 15px;
}

// Buttons
.primary-btn,
.primary-btn:focus {
  @include colors($white, $blue);
}

.primary-btn:hover {
  @include colors($blue, $white);
}

.btn-dark-border,
.btn-dark-border:focus {
  color: $white;
  border: 0.5px solid $black-light;
}

/* Change the background color of the pagination buttons */
.ui-paginator .ui-paginator-pages .ui-paginator-page {
  background-color: #007bff;
  /* Replace with your desired color */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.prefix-form {
  position: absolute;
  top: 27%;
  left: 3%;
  width: 12px;
}

.suffix-form {
  position: absolute;
  top: 55%;
  right: 8%;
}

.roundedCorner {
  max-height: 492px;
  overflow-y: auto;
  border-radius: 8px;
}

.max-width-cell {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: $black-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  // Navifly_loader.gif  Navifly_loader_transparent.gif
  // background: url('assets/common-layout/Navifly_loader_transparent.gif') 60% 40% no-repeat rgba(255, 255, 255, 0.6);
  // background: url('assets/common-layout/Rolling-White2.gif') 50% 50% no-repeat rgba(0, 0, 0, 0.6);
  // Rolling-yellow.gif
  // background: url('assets/common-layout/SquareLoader.gif') 50% 50% no-repeat rgb(0, 0, 0);
  // background-size: 30px;
  // opacity: 0.8;
}

.awbloader {
  color: #fff;
  background: #0A0D14;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 100000 !important;
  height: 100%;
  width: 100%;
}

//Error Messages
.error-message {
  font-size: 12px;
  color: $red;
  // @extend .br-red;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

// Cesium
.cesium-viewer .cesium-widget-credits {
  display: none !important;
}

//Material CSS, DO NOT EDIT
tr.mat-header-row {
  height: 2.5rem !important;
  border-bottom: 1px solid #3f444d;
  background: #23272f;
  color: var(--text-icon-primary-white, #fff);

  font-size: 0.875rem;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: 0.0175rem;
  text-transform: capitalize;
}

tr.mat-row {
  height: 2.5rem !important;
  border-bottom: 1px solid #3f444d;
  background: #1b1f27;
  padding: 0.25rem 0rem !important;

  color: #fff;
  font-size: 0.875rem;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  // text-decoration-line: underline;
}

mat-drawer-container,
mat-drawer-content {
  height: 100%;
  scrollbar-width: none !important;
}

.mat-drawer-container {
  position: static !important;
  // flex: 1 1 auto;
  box-shadow: -4px 0px 10px 0px rgba(66, 65, 65, 0.79);

  mat-drawer {
    box-shadow: -4px 0px 10px 0px rgba(66, 65, 65, 0.79);
  }
}

.mat-drawer-transition .mat-drawer-backdrop {
  background: black;
  opacity: 0.5;
}

.no-data-row {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  padding: 10px !important;
  width: 100%;
}

.leaflet-tooltip-content-wrapper {
  max-width: 300px !important;
}

.custom-leaflet-tooltip {
  max-width: 240px;
  padding: 10px;
  border-radius: 5px;
  white-space: normal;
  position: relative;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.name-info {
  display: flex;
  flex-direction: column;
}

.cover-zipcode,
.tooltip-lat-long {
  display: flex;
  flex-direction: column;
}

.num {
  color: rgba(46, 82, 145, 1);
}

.zipcodeOptions {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}

.tool-tip-desc {
  display: flex;
  flex-direction: row;
}

.tooltip-content {
  display: flex;
  flex-direction: row;
}

.address {
  font-size: 10px;
  max-width: 214px;
  font-weight: 600;
  word-wrap: break-word;
}

/* Additional responsive styles */
@media only screen and (max-width: 480px) {

  /* Adjust font size for smaller screens */
  .f-12 {
    font-size: 10px;
  }

  .f-14 {
    font-size: 12px;
  }

  /* Limit width to fit smaller screens */
  .custom-leaflet-tooltip {
    max-width: 180px;
  }

  /* Limit width and word-wrap for address */
  .address {
    max-width: 100%;
    word-wrap: break-word;
  }
}

.material-symbols-rounded {
  font-size: 20px !important;
}

.mat-menu-content:not(:empty) {
  border: 1px solid var(--neutral-700, #3F444D);
  background: var(--neutral-900, #1B1F27);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    color: #FFF;
  }
}

.mat-slide-toggle-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.slideToggle-button .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #b3d6eb;
  opacity: 2;
}

.slideToggle-button .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3d7bf7;
}

//mat progress bar
.mat-progress-bar-fill::after {
  background-color: #91B5FD;
}

.mat-progress-bar-buffer {
  background-color: #5F6C85;
}


.fcsa {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.fcsb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fcse {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fcsc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fr {
  display: flex;
  flex-direction: row;
}

.frsa {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.frsb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.frsc {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.p-table {
  background-color: #1b1f27 !important;
}

.p-table thead tr {
  background-color: #333;
  color: #fff;
}

/* Set the background color of the table body */
.inner-table .p-table tbody tr {
  background-color: #fff !important;
}

.history-dialog {
  mat-dialog-container {
    padding: 1rem;
    background-color: #23272f;
  }
}

// ===================notification===================
.custom-snackbar {
  background-color: #1B1F27 !important;
  width: 40vw !important;
  color: #fff !important;
}

.mat-tab-label {
  height: 55px !important;
  padding: 0 24px;
  min-width: 50px !important;
}

.mat-tab-label:hover {
  opacity: 1 !important;
}

.mat-ink-bar {
  background-color: #007bff !important;
}

.mat-form-field-infix {
  border-top: 0px solid transparent;
}

.mat-tab-labels {
  height: 55px !important;
}

.mat-tab-label {
  height: 55px !important;
  font-size: 16px !important;
}