$black-dark: #1b1f27;
$black-medium:#23272f;
$black-light: #3f444d;

$white-opacity: rgba(255, 255, 255, 0.75);
$white: #fff;

$scroll-bar: #adadad;

$grey: #eaecf0;

$blue: #3d7bf7;
$light-blue: #91b5fd;

$green: #6ee7bf;

$red : #dc3545;
$dark-red: #ef4352;


$yellow: #fdcf72;
